import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import head from "../assets/img/head_svg.svg";
import Stack from 'react-bootstrap/Stack';
import css from "../assets/img/css.png";
import html from "../assets/img/html.png";
import javascript from "../assets/img/javascript.png";
import mongodb from "../assets/img/mongodb.png";
import nodejs from "../assets/img/nodejs.png";
import react from "../assets/img/react.png";
import sql from "../assets/img/sql.png";
import bootstrap from "../assets/img/bootstrap.png";
import reactnative from "../assets/img/react_native.png";
import wordpress from "../assets/img/wordpress_logo2.png";

export const SkillsSection = () => {

    return (
        <section id="skills" className="Section">
            <Container>
                <h2 class="section_heading">About</h2>
                <Row>
                    <Col xxl={5} class="col-5 align-items-center justify-content-center">
                        <div id="head">
                            <img src={head} alt="" id="head_svg"/>
                        </div>
                        <div id="whoami">
                            Fully committed to the philosophy of life-long<br class="hidden_br"></br> learning, 
                            I’m a software developer with knowledge about JavaScript, React and all things web development. 
                            The unique combination of creativity, logic,<br class="hidden_br"></br> technology and never running out of new things to discover, 
                            drives my excitement and passion for web development. 
                            When I’m not at my computer I like to spend my time reading, keeping fit and watching movies.
                        </div>
                    </Col>
                    <Col lg={2}></Col>
                    <Col xxl={5} xl={12}>
                        <div id="skills_1">
                            <Stack id="skill_column" gap={4}>
                                <div class="skill">
                                    <img src={html} alt=""/>
                                    <div class="skill_text">HTML</div>
                                </div>
                                <div class="skill">
                                    <img src={css} alt=""/>
                                    <div class="skill_text">CSS</div>
                                </div>
                                <div class="skill">
                                    <img src={javascript} alt=""/>
                                    <div class="skill_text">JavaScript</div>
                                </div>
                            </Stack>
                            <Stack id="skill_column_middle" gap={4}>
                                <div class="skill">
                                    <img src={react} alt=""/>
                                    <div class="skill_text">React</div>
                                </div>
                                <div class="skill">
                                    <img src={bootstrap} alt=""/>
                                    <div class="skill_text">Bootstrap</div>
                                </div>
                                <div class="skill">
                                    <img src={reactnative} alt=""/>
                                    <div class="skill_text">React Native</div>
                                </div>
                                <div class="skill">
                                    <img src={wordpress} alt=""/>
                                    <div class="skill_text">Wordpress</div>
                                </div>
                            </Stack>
                            <Stack id="skill_column2" gap={4}>
                                <div class="skill">
                                    <img src={nodejs} alt=""/>
                                    <div class="skill_text">NodeJS</div>
                                </div>
                                <div class="skill">
                                    <img src={mongodb} alt=""/>
                                    <div class="skill_text">mongodb</div>
                                </div>
                                <div class="skill">
                                    <img src={sql} alt=""/>
                                    <div class="skill_text">SQL</div>
                                </div>
                            </Stack>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}