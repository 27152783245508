import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Col from 'react-bootstrap/Col';

export const NavBar = (props) => {
    //console.log(props.isVisible1);

    return (
        <Navbar sticky="top">
            <Container>
            
            <Col xxl={8} lg={7}></Col>
            <Col id="navbar_column" xxl={4} lg={5} md={7} sm={9}>
                
                <Nav className="me-auto">
                    <Nav.Link href="#home" className={props.isVisible ? "red_text" : "white_text"}>
                        Home
                    </Nav.Link>
                    <Nav.Link href="#skills" className={props.isVisible1 ? "red_text" : "white_text"}>
                        About
                    </Nav.Link>
                    <Nav.Link href="#projects" className={props.isVisible2 ? "red_text" : "white_text"}>
                        Services
                    </Nav.Link>
                    <Nav.Link href="#contact" className={props.isVisible3 ? "red_text" : "white_text"}>
                        Contact
                    </Nav.Link>
                </Nav>
                
            </Col>
            
            </Container>
        </Navbar>
    )
}