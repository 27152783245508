import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import coding_img from "../assets/img/coding_0.jpg";
import wordpress_img from "../assets/img/wordpress.jpg";
export const Projects = () => {

    return (
        <section id="projects">
            <Container id="project_container">
                <h2 class="section_heading">Services</h2>
                <Row>
                    <Col>
                        <h1 class="description_heading"><div class="red_text"></div>// Hand Coded Websites</h1>
                        <div class="description">
                            You want a custom website that is tailored for your purposes? You don't want to worry about SEO and cyber attacks anymore?
                            Then hand coded websites - like the one your are looking right now - are the best option for you. <br/>
                            Hand Coding is the traditional way of building a website where you have to write 
                            code from scratch. Choosing this option will give you an entirely bespoke website, designed specifically for your needs.
                        </div>
                    </Col>
                    <Col id="image_column">
                        <img src={coding_img} alt="" class="project_image"/>
                    </Col>
                </Row>
                <Row id="project_row">
                    <Col>
                        <h1 class="description_heading">// Wordpress Development</h1>
                        <div class="description">
                            WordPress is a content management system (CMS) that is used to build websites.
                            Why pick this approach? Well, because it’s quick, it’s easy and can be done with virtually no budget. 
                            <br/>For an amateur website that has few requirements in terms of functionality, the resulting website may be more than sufficient, 
                            especially if you only require it to host simple information and imagery.
                        </div>
                    </Col>
                    <Col id="image_column">
                        {/* lg={{order: 1 }} */}
                        <img src={wordpress_img} alt="" id="wordpress_image"/>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}