import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { useState } from "react";

export const Contact = () => {
    const formInitialDetails = {
        name: '',
        email: '',
        message: ''
    }

    const [formDetails, setFormDetails] = useState(formInitialDetails);
    const [status, setStatus] = useState({});
    
    const onFormUpdate = (category, value) => {
        setFormDetails({
            ...formDetails,
            [category]: value
        })
    }
    
    const handleSubmit = async (e) => {
        e.preventDefault();
        let response = await fetch("https://portfoliowebsi.herokuapp.com/contact", {
          method: "POST",
          headers: {
            "Content-Type": "application/json;charset=utf-8",
            /*"Access-Control-Allow-Origin": "*",*/
          },
          body: JSON.stringify(formDetails),
        });
        let result = await response.json();
        setFormDetails(formInitialDetails);
        if (result.code === 200) {
          setStatus({ succes: true, message: 'Your message was sent successfully. I will review your request as soon as possible.'});
        } else {
          setStatus({ succes: false, message: 'Something went wrong, please try again later.'});
        }
    };

    return (
        <section id="contact">
            <Container>
                <h2 class="section_heading">Contact</h2>
                <Row>
                    <Col lg={4} md={2}></Col>
                    <Col lg={5} md={8} id="center_column" className="align-middle">
                        <div id="contact_text">Have a question or want to work together? Leave your details and I'll get back to you as soon as possible.</div>
                        <Form onSubmit={handleSubmit}>
                            <Form.Group className="mb-3" controlId="name">
                                <Form.Control value={formDetails.name} onChange={(e) => onFormUpdate('name', e.target.value)} 
                                                className="text-light bg-dark border-0" type="text" placeholder="Name" />
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="email">
                                <Form.Control value={formDetails.email} onChange={(e) => onFormUpdate('email', e.target.value)}
                                                className="text-light bg-dark border-0" type="email" placeholder="Email" />
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="message">
                                <Form.Control value={formDetails.message} onChange={(e) => onFormUpdate('message', e.target.value)}
                                                className="text-light bg-dark border-0" as="textarea" rows={4} placeholder="Message"/>
                            </Form.Group>
                            <Row>
                                <Col xs={3}>
                                    <Button variant="outline-danger" type="submit" id="submit_button">
                                        SUBMIT
                                    </Button>
                                </Col>
                                <Col id="success_message">
                                    {
                                        status.message &&
                                            <p className={status.success === false ? "danger" : "success"}>{status.message}</p>
                                    }
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}