import { useEffect } from "react";

export const Home = () => {
    useEffect(() => {
        let canvas = document.getElementById('canvas'),
        can_w = parseInt(canvas.getAttribute('width')),
        can_h = parseInt(canvas.getAttribute('height')),
        ctx = canvas.getContext('2d');

        // console.log(typeof can_w);
        let mouse_distance;
        //let mouse_distance_2;
        let diagonal = Math.sqrt(can_w*can_w + can_h*can_h);
        let BALL_NUM = Math.floor(diagonal / 7);//400;

        /*let ball = {
            x: 0,
            y: 0,
            vx: 0,
            vy: 0,
            r: 0,
            alpha: 1,
            phase: 0
        };*/
        let ball_color = {
            r: 81,
            g: 162,
            b: 233
        },
        R = 1.5,
        balls = [],
        mouse_balls = [],
        alpha_f = 0.03,
        //alpha_phase = 0,
            
        // Line
        link_line_width = 0.4,
        dis_limit = 100,
        //add_mouse_point = true,
        mouse_in = false,
        mouse_ball = {
            x: 0,
            y: 0,
            vx: 0,
            vy: 0,
            r: 0,
            type: 'mouse'
        };

        // Random speed
        function getRandomSpeed(pos){
            let  min = -0.5,
            max = 0.5;
            switch(pos){
                case 'top':
                    return [randomNumFrom(min, max), randomNumFrom(0.1, max)];
                case 'right':
                    return [randomNumFrom(min, -0.1), randomNumFrom(min, max)];
                case 'bottom':
                    return [randomNumFrom(min, max), randomNumFrom(min, -0.1)];
                case 'left':
                    return [randomNumFrom(0.1, max), randomNumFrom(min, max)];
                default:
                    return;
            }
        }
        function randomArrayItem(arr){
            return arr[Math.floor(Math.random() * arr.length)];
        }
        function randomNumFrom(min, max){
            return Math.random()*(max - min) + min;
        }
        //console.log(randomNumFrom(0, 10));
        // Random Ball
        function getRandomBall() {
            let pos = randomArrayItem(['top', 'right', 'bottom', 'left']);
            // eslint-disable-next-line default-case
            switch(pos){
                case 'top':
                    return {
                        x: randomSidePos(can_w),
                        y: -R,
                        vx: getRandomSpeed('top')[0],
                        vy: getRandomSpeed('top')[1],
                        r: R,
                        alpha: 1,
                        phase: randomNumFrom(0, 10)
                    }
                case 'right':
                    return {
                        x: can_w + R,
                        y: randomSidePos(can_h),
                        vx: getRandomSpeed('right')[0],
                        vy: getRandomSpeed('right')[1],
                        r: R,
                        alpha: 1,
                        phase: randomNumFrom(0, 10)
                    }
                case 'bottom':
                    return {
                        x: randomSidePos(can_w),
                        y: can_h + R,
                        vx: getRandomSpeed('bottom')[0],
                        vy: getRandomSpeed('bottom')[1],
                        r: R,
                        alpha: 1,
                        phase: randomNumFrom(0, 10)
                    }
                case 'left':
                    return {
                        x: -R,
                        y: randomSidePos(can_h),
                        vx: getRandomSpeed('left')[0],
                        vy: getRandomSpeed('left')[1],
                        r: R,
                        alpha: 1,
                        phase: randomNumFrom(0, 10)
                    }
            }
        }
        
        function randomSidePos(length){
            return Math.ceil(Math.random() * length);
        }

        // Draw Ball
        function renderBalls(){
            Array.prototype.forEach.call(balls, function(b){
            if(!b.hasOwnProperty('type')){
                ctx.fillStyle = 'rgba('+ball_color.r+','+ball_color.g+','+ball_color.b+','+b.alpha+')';
                ctx.beginPath();
                ctx.arc(b.x, b.y, R, 0, Math.PI*2, true);
                ctx.closePath();
                ctx.fill();
            }
            });
        }

        // Update balls
        function updateBalls(){
            let new_balls = [];
            Array.prototype.forEach.call(balls, function(b){
                b.x += b.vx;
                b.y += b.vy;
                
                if(b.x > -(50) && b.x < (can_w+50) && b.y > -(50) && b.y < (can_h+50)){
                new_balls.push(b);
                }
                //console.log(b.x);
    
                //mouse_distance_2 = getDisOf(b.x, mouse_ball);
                // alpha change if mouse not in
                //if (mouse_in === false || mouse_distance_2 > 400) {
                    b.phase += alpha_f;
                    b.alpha = Math.abs(Math.cos(b.phase));
                //} else if (mouse_in === true && mouse_distance_2 < 400) {
                    //b.alpha = 1;
                //}
                // console.log(b.alpha);
            });
            
            balls = new_balls.slice(0);
        }

        // Draw lines
        function renderLines(){
            let fraction;
            for (let i = 0; i < balls.length; i++) {
                mouse_distance = getDisOf(balls[i], mouse_ball);
                for (let j = i + 1; j < balls.length; j++) {
                
                    fraction = getDisOf(balls[i], balls[j]) / dis_limit;
                    
                    //console.log(mouse_ball.x);
                    //console.log(mouse_distance);

                    if(fraction < 1 && mouse_distance < 200){ //Hier prüfen ob es auch close zur Mouse Position ist
                        //alpha = (1 - fraction).toString();

                        ctx.lineWidth = link_line_width;
                        //balls[i].alpha = 1;
                        //balls[i].phase = 1;

                        ctx.beginPath();
                        ctx.strokeStyle = 'rgba(81,162,233,'+ 10/*alpha*/+')';
                        ctx.moveTo(balls[i].x, balls[i].y);
                        ctx.lineTo(balls[j].x, balls[j].y);
                        //ctx.globalAlpha = 0.5;
                        ctx.stroke();
                        ctx.closePath();
                    }

                    if(fraction < 1 && mouse_distance < 100){
                        //ctx.strokeStyle = 'rgba(156,217,249,'+ 0.5/*alpha*/+')';
                        //ctx.lineWidth = link_line_width;
                        
                        ctx.beginPath();
                        ctx.lineWidth = link_line_width;
                        ctx.strokeStyle = 'rgba(81,162,233,'+ 1/*alpha*/+')'
                        ctx.moveTo(balls[i].x, balls[i].y);
                        ctx.lineTo(mouse_ball.x, mouse_ball.y);
                        ctx.stroke();
                        ctx.closePath();
                    }

                }
            }
        }

        // calculate distance between two points
        function getDisOf(b1, b2){
            let  delta_x = Math.abs(b1.x - b2.x),
            delta_y = Math.abs(b1.y - b2.y);
            
            return Math.sqrt(delta_x*delta_x + delta_y*delta_y);
        }

        // add balls if there a little balls
        function addBallIfy(){
            if(balls.length < BALL_NUM){
                balls.push(getRandomBall());
            }
        }

        // Render
        function render(){
            ctx.clearRect(0, 0, can_w, can_h);
            
            renderBalls();
            
            if (mouse_in === true && diagonal > 1400) {
                renderLines();
            }

            updateBalls();
            
            addBallIfy();
            
            window.requestAnimationFrame(render);
        }

        // Init Balls
        function initBalls(num){
            for(let i = 1; i <= num; i++){
                balls.push({
                    x: randomSidePos(can_w),
                    y: randomSidePos(can_h),
                    vx: getRandomSpeed('top')[0],
                    vy: getRandomSpeed('top')[1],
                    r: R,
                    alpha: 1,
                    phase: randomNumFrom(0, 10)
                });
            }
        }
        // Init Canvas
        function initCanvas(){
            canvas.setAttribute('width', window.visualViewport.width);
            canvas.setAttribute('height', window.innerHeight);
            
            can_w = parseInt(canvas.getAttribute('width'));
            can_h = parseInt(canvas.getAttribute('height'));
        }
        window.addEventListener('resize', function(e){
            //console.log('Window Resize...');
            initCanvas();
            diagonal = Math.sqrt(can_w*can_w + can_h*can_h);
            BALL_NUM = Math.floor(diagonal / 7);
            //initBalls(BALL_NUM);
            //goMovie();
        });

        function goMovie(){
            initCanvas();
            initBalls(BALL_NUM);
            window.requestAnimationFrame(render);
        }
        goMovie();

        // Mouse effect
        canvas.addEventListener('mouseenter', function(){
            //console.log('mouseenter');
            mouse_in = true;
            //balls.push(mouse_ball);
            mouse_balls = [];
            mouse_balls.push(mouse_ball);
            //console.log(mouse_balls);
            renderLines();
        });

        canvas.addEventListener('mouseleave', function(){
            let new_balls = [];
            //console.log('mouseleave');
            mouse_in = false;
            Array.prototype.forEach.call(balls, function(b){
                if(!b.hasOwnProperty('type')){
                    new_balls.push(b);
                }
            });
            balls = new_balls.slice(0);
        });

        canvas.addEventListener('mousemove', function(e){
            e = e || window.event;
            mouse_ball.x = e.pageX;
            mouse_ball.y = e.pageY;
            //ctx.beginPath();
            //ctx.globalAlpha = 1;
            // console.log(mouse_ball);
        });

    }, [])

    return (
        <section id="home">
            <div id= "wrapper">
                <canvas id="canvas" width="800" height="800">
                </canvas>
                <div id="heading">
                    <div id="heading__line-1">Hello, I'm 
                        <span id="name_span"> Jannis</span>.
                    </div>
                    <div id="heading__line-2">I am a software developer.</div>
                    <a href="#skills" id="heading__link">
                        <div id="heading_link_text">View my work 
                            <svg id="heading__arrow" version="1.1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><title>arrow-right</title><path d="M11.293 5.707l5.293 5.293h-11.586c-0.552 0-1 0.448-1 1s0.448 1 1 1h11.586l-5.293 5.293c-0.391 0.391-0.391 1.024 0 1.414s1.024 0.391 1.414 0l7-7c0.092-0.092 0.166-0.202 0.217-0.324 0.101-0.245 0.101-0.521 0-0.766-0.049-0.118-0.121-0.228-0.217-0.324l-7-7c-0.391-0.391-1.024-0.391-1.414 0s-0.391 1.024 0 1.414z"></path>
                            </svg>
                        </div>
                    </a>
                </div>
            </div>
        </section>
    );
}