import './App.css';
import { NavBar } from './components/NavBar.js';
import { Home } from './components/Home.js';
import { SkillsSection } from './components/SkillsSection.js';
import { Projects } from './components/Projects.js';
import { Contact } from './components/Contact.js';
import { Footer } from './components/Footer.js';
import { useRef } from "react";
import { useOnScreen } from './components/useOnScreen.js';

function App() {

  const ref = useRef()
  const isVisible = useOnScreen(ref)

  const ref1 = useRef()
  const isVisible1 = useOnScreen(ref1)

  const ref2 = useRef()
  const isVisible2 = useOnScreen(ref2)

  const ref3 = useRef()
  const isVisible3 = useOnScreen(ref3)

  return (
    <div className="App">
      <title>My Portfolio | About</title>
      <div ref={ref}>
        <Home/>
      </div>
      <NavBar isVisible={isVisible} isVisible1={isVisible1} isVisible2={isVisible2} isVisible3={isVisible3}>
      </NavBar>
      <div ref={ref1}>
        <SkillsSection/>
      </div>
      <div ref={ref2}>
        <Projects/>
      </div>
      <div ref={ref3}>
        <Contact/>
      </div>
      <Footer/>
    </div>
  );
}

export default App;