import { useEffect, useState } from "react";
export const useOnScreen= (ref) => {
    const options = {
        rootMargin: '0px',
        /*threshold: [0.4, 0.4, 0.4]*/
        /*threshold: [0.99, 0.99, 0.6]*/
        threshold: [0.99, 0.99, 0.6]
      }

    const [isIntersecting, setIntersecting] = useState(false)
  
    const observer = new IntersectionObserver(
      ([entry]) => setIntersecting(entry.isIntersecting), options
    )
  
    useEffect(() => {
        observer.observe(ref.current)
        // Remove the observer as soon as the component is unmounted
        return () => { observer.disconnect() }
    }, [])
  
    return isIntersecting
  }