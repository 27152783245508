import { Container, Row, Col } from "react-bootstrap";
import upbutton from "../assets/img/up_button.svg";
export const Footer = () => {

    return (
        <footer className="footer">
            <Container>
                <Row>
                    <Col xs={12}  className="text-center">
                        <div className="" id="up_button">
                            <a href="#"><img src={upbutton} alt="Icon" /></a>
                        </div>
                        {/*<div className="social-icon">
                            <a href="#"><img src={upbutton} alt="Icon" /></a>
                            <a href="#"><img src={upbutton} alt="Icon" /></a>
                        </div>*/}
                        <p>JANNIS L. ©2022</p>
                    </Col>
                </Row>
            </Container>
        </footer>
    )
}